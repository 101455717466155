import { useState, useEffect } from 'react'
import axios from 'axios';


const ProductsAPI = () => {

    const [products, setProducts] = useState([]);
    const [callback, setCallback] = useState(false);
    const [category, setCategory] = useState('')
    const [size, setSize] = useState('');
    const [sort, setSort] = useState('')
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [result, setResult] = useState(0)



    useEffect(() => {
        const getProducts = async () => {
            const res = await axios.get(`/api/products?limit=${page*300}&${category}&${size}&${sort}&title[regex]=${search}`);
            setProducts(res.data.products);
            setResult(res.data.result);
        }
        getProducts();
    }, [callback, category, sort, search, page, size])
    

    return {
        products: [products, setProducts],
        callback: [callback, setCallback],
        category: [category, setCategory],
        sort: [sort, setSort],
        search: [search, setSearch],
        page: [page, setPage],
        result: [result, setResult],
        size: [size, setSize]
    }
}

export default ProductsAPI
