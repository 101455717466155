import React, { useState, useContext } from 'react'
import { GlobalState } from '../../../../GlobalState';
import axios from 'axios';

const Size = ({ sizes, id, index }) => {
    const state = useContext(GlobalState);
    const [cart, setCart] = state.userAPI.cart;
    const [token] = state.token;
    const [size, setSize] = state.size;

    const addToCart = async () => {
        await axios.patch('/user/addcart', { cart }, {
            headers: { Authorization: token }
        })
    }

    const changeSize = (id, size, index) => {
        setSize(size)
        // cart.forEach((item, i) => {
        //     if (item._id === id && index == i) {
        //         item.size_choice = size;
        //     }
        // });

        // setCart([...cart]);
        // addToCart();
    }

    if(sizes.length == 1) {
        sizes =  sizes[0].split(', ');
     }

    return (
        <div className="sizes" >

            <select name="size" id="size" value={size} onChange={(event) => changeSize(id, event.target.value, index)} >
                <option value="0">Изберете размер</option>
                {
                   
                        sizes.map((size, index) => (
                        <option value={size} key={index}>{size}</option>
                        ))
                   
                }
            </select>

        </div>
    )
}

export default Size
